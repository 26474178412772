






































import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  metaInfo: {
    title: 'Dashboard',
  },
  components: {},
  props: {
    periodId: String,

    fluid: Boolean,
  },
  data: () => ({}),
  computed: {
    ...mapGetters(['commissionQuotaPeriods']),
  },
  methods: {
    onPeriodChange(v: number): void {
      this.$router.replace({ params: { periodId: v.toString() } })
    },
  },
})
